<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="4" :sm="24">
            <a-form-item label="仓库">
              <a-select
                show-search
                allow-clear
                v-model="queryParam.warehouse_id"
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="仓库名称"
                @search="handleWmsWarehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="库位">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                allow-clear
                :filter-option="filterOption"
                option-filter-prop="children"
                :not-found-content="null"
                show-search
                v-decorator="[
                  'cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                :default-active-first-option="false"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="商品">
              <a-input v-model="queryParam.goods_name" placeholder="请输入名称" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select
                allow-clear
                v-model="queryParam.status"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.outflow_status">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 70px; max-width: 80px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            v-if="record.status === 1"
            title="请选完成或取消出库"
            ok-text="完成出库"
            cancel-text="取消出库"
            @confirm="handleStatus(2 ,record)"
            @cancel="handleStatus(3, record)"
          >
            <a>修改状态</a>
          </a-popconfirm>
          <a-divider
            type="vertical"
            v-if="record.status !== 2"
          />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            v-if="record.status !== 2"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '@/views/c-wms/outflow/modules/CreateForm'
import {
  warehouse_list,
  wms_common_warehouse_outflow_create,
  wms_common_warehouse_outflow_delete,
  wms_common_warehouse_outflow_list, wms_common_warehouse_outflow_partial_update
} from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'serial',
          width: 50,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          align: 'center',
          dataIndex: 'warehouse'
        },
        {
          title: '库位',
          align: 'center',
          dataIndex: 'cargo_space'
        },
        {
          title: '批次',
          align: 'center',
          dataIndex: 'commodity'
        },
        {
          title: '商品',
          align: 'center',
          ellipsis: true,
          dataIndex: 'goods_name'
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.outflow_status[text]
        },
        {
          title: '创建时间',
          align: 'center',
          ellipsis: true,
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wms_common_warehouse_outflow_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.handleWmsWarehouseSearch(undefined)
  },
  methods: {
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleStatus (status, record) {
      console.log()
      console.log(record.id, status)
      wms_common_warehouse_outflow_partial_update({ status: status }, record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleDelet (record) {
      console.log(record.id)
      wms_common_warehouse_outflow_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          wms_common_warehouse_outflow_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    filterOption (input, option) {
      // console.log(input, option.componentOptions.children[0].text, this.dataSource_wms_warehouse.length + '-------------------------')
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // 仓库查询
    handleWmsWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      })
    },
    handleWmsWarehouseChange (value) {
      console.log(value)
      this.queryParam.warehouse_id = value
      if (value === undefined) {
        // 如果仓库清除，那仓库对应的库位数组也要清空
        this.dataSource_wms_cargo_space = []
        this.handleWmsWarehouseSearch(undefined)
      } else {
        // 选择了仓库，那下一个库位就是对应仓库的库位
        this.handleWmsCargoSpaceSearch(value)
      }
    },
    // 库位查询
    handleWmsCargoSpaceSearch (value) {
      cargo_space_list({ wms_supervised_warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_cargo_space = result
      })
    },
    handleWmsCargoSpaceChange (value) {
      this.queryParam.cargo_space_id = value
    }
  }
}
</script>
