<template>
  <div>
    <a-form :form="form" style="width: 60%; margin: 0 auto;">
      <a-row :gutter="[16,16]">
        <a-col :span="10" :offset="5">
          <a-form-item label="序号" >
            <a-input-number :min="1" v-decorator="['serial', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            <span style="margin-left: 30px"><span style="color: red">提示：</span>序号小的在前边</span>
          </a-form-item>
          <a-form-item label="货品名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="英文名称" >
            <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="货品编码" >
            <a-input v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="海关编码" >
            <a-input v-decorator="['hs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:16, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="货品单位" >
            <a-input v-decorator="['unit', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="法定单位" >
            <a-select
              v-decorator="[
                'legal_unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="原产地" >
            <a-select
              v-decorator="[
                'original_place',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="关税税率" >
            <a-input
              addon-after="%"
              v-decorator="['tariff_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="消费税" >
            <a-input
              addon-after="%"
              v-decorator="['consumption_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="增值税" >
            <a-input
              addon-after="%"
              v-decorator="['value_added_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="开启保质期" >
            <a-switch v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关" @change="openSelfLife"/>
          </a-form-item>
          <a-form-item label="保质期" v-if="open_self_life">
            <a-input-number v-decorator="['shelf_life', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="保质期单位" v-if="open_self_life">
            <a-select
              v-decorator="[
                'shelf_life_unit',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              style="width: 90px"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.shelf_life_unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="备注" >
            <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
        style="width: 60%; margin: 0 auto"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="4" :offset="2">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { goods_list_id } from '@/api/c_wms_goods'
import { wms_common_warehouse_outflow_partial_update } from '@/api/c_wms_warehouse'
// 表单字段
const fields = ['id', 'name', 'en_name', 'code', 'original_place', 'hs_code', 'unit', 'legal_unit', 'open_self_life',
  'shelf_life', 'legal_unit', 'shelf_life_unit', 'tariff_rate', 'consumption_tax_rate', 'value_added_tax_rate', 'is_active', 'total_count', 'serial', 'remark'
]
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      location: '',
      wms_goodss: [],
      open_self_life: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
      this.openSelfLife(this.record.open_self_life)
    })
  },
  methods: {
    openSelfLife (value) {
      this.open_self_life = !!value
    },
    handleGoBack () {
      this.$emit('onGoBack')
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        console.log('--------------------', values)
        values.tariff_rate = (values.tariff_rate / 100).toFixed(2)
        values.consumption_tax_rate = (values.consumption_tax_rate / 100).toFixed(2)
        values.value_added_tax_rate = (values.value_added_tax_rate / 100).toFixed(2)
        wms_common_warehouse_outflow_partial_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    handleGetInfo () {

    },
    wmsWarehouseList () {
      goods_list_id().then(res => {
        this.wms_goodss = res.data
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        formData.shelf_life_unit = data.shelf_life_unit.toString()
        formData.tariff_rate = Math.round(data.tariff_rate * 100)
        formData.consumption_tax_rate = Math.round(data.consumption_tax_rate * 100)
        formData.value_added_tax_rate = Math.round(data.value_added_tax_rate * 100)

        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
