<template>
  <a-modal
    title="出库"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="仓库">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'warehouse_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="仓库名称"
                @search="handleWmsWarehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="库位">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="filterOption"
                option-filter-prop="children"
                :not-found-content="null"
                show-search
                v-decorator="[
                  'cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                :default-active-first-option="false"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="商品">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                show-search
                :default-active-first-option="false"
                v-decorator="[
                  'commodity_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="商品"
                @change="handleCommodityChange"
                @search="getCommodity"
              >
                <a-select-option v-for="d in dataSource_wms_commodity" :key="d.wms_supervised_warehouse_commodity_id" :tt="d.wms_supervised_warehouse_goods + '(' + d.wms_supervised_warehouse_commodity+ ')' ">
                  {{ d.wms_supervised_warehouse_goods }} ({{ d.wms_supervised_warehouse_commodity }})
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="出库数量">
              <a-input-number :min="0" v-decorator="['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { corporation_list } from '@/api/corporation'
import { wms_warehouse_inventory_list } from '@/api/c_wms_period_inventory'

// 表单字段
const fields = ['id', 'batch', 'count', 'wms_supervised_warehouse_goods_id', 'wms_supervised_warehouse_goods']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      fetching: false,
      corporationList: [],
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      wms_commodity_purchases: [],
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      dataSource_wms_goods: [],
      dataSource_wms_commodity: []
    }
  },
  created () {
    this.handleWmsWarehouseSearch(undefined)
    // 防止表单未注册
    setTimeout(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      // // 当 model 发生改变时，为表单设置值
      this.model && this.form.setFieldsValue(pick(this.model, fields))
      this.$watch('model', () => {
        this.model && this.form.setFieldsValue(pick(this.model, fields))
      })
    })
  },
  mounted () {},
  methods: {
    fetchData (value) {
      console.log('fetching user', value)
      this.corporationList = []
      this.fetching = true
      corporation_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.corporationList = !value ? [] : result
      })
    },
    handleDataChange (value) {
      console.log(value)
    },
    filterOption (input, option) {
      // console.log(input, option.componentOptions.children[0].text, this.dataSource_wms_warehouse.length + '-------------------------')
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    wmsWarehouseList () {
    },
    handleWmsWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      })
    },
    handleWmsWarehouseChange (value) {
      console.log(value)
      const objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      var obj = Object.assign({}, objneo)
      console.log(obj)
      this.dataSource_wms_cargo_space = undefined
      this.handleWmsCargoSpaceSearch2(value)
    },
    handleWmsCargoSpaceSearch2 (value) {
      cargo_space_list({ wms_supervised_warehouse_id: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_cargo_space = result
      })
    },
    handleWmsCargoSpaceChange (value) {
      console.log(value)
      const objneo = this.dataSource_wms_cargo_space.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_cargo_space)
      var obj = Object.assign({}, objneo)
      console.log(obj)
    },
    handleCommodityChange (value) {
      console.log(value)
      // const objneo = this.dataSource_wms_commodity.find((item) => {
      //   return item.id === value
      // })
      // console.log(objneo, 'objneo', this.dataSource_wms_commodity)
      // var obj = Object.assign({}, objneo)
      // console.log(obj)
      this.getCommodity(value)
    },
    getCommodity (value) {
      console.log(value)
      const common_warehouse_id = this.form.getFieldValue('warehouse_id')
      const common_warehouse_cargo_space_id = this.form.getFieldValue('cargo_space_id')
      if (!(common_warehouse_id && common_warehouse_cargo_space_id)) {
        return
      }
      const requestBody = { common_warehouse_id: common_warehouse_id, common_warehouse_cargo_space_id: common_warehouse_cargo_space_id, goods_name: value }
      wms_warehouse_inventory_list(requestBody).then(res => {
        console.log(res)
        this.dataSource_wms_commodity = res.data.entries
      })
    }
  }
}
</script>
